<template>
  <b-row role="tablist" class="jukiaccordion">
    <b-col
      xl="12"
      v-for="(item, index) in contentlist.content"
      :class="right ? 'right' : 'left'"
      :key="index"
    >
      <div
        :class="[
          visible[index] ? null : 'collapsed',
          'mt-4',
          right ? 'pr-4' : 'pl-2',
        ]"
        :aria-expanded="visible[index] ? 'true' : 'false'"
        :aria-controls="generatedId[index]"
        @click="toggle(index)"
      >
        <h3 class="pl-4">
          <i
            :class="[
              visible[index] ? 'acc-toggle-down' : 'acc-toggle-up',
              'acc-toggle',
            ]"
          >
            <img :src="require('@/assets/favicon.png')" alt="Ekuja-Männchen" />
          </i>
          {{ item.title }}
        </h3>
      </div>
      <b-collapse
        :id="generatedId[index]"
        class="accordion-content ml-2 p-2 pl-4 text-block"
        :accordion="accordionName"
        role="tabpanel"
        v-model="visible[index]"
        v-html="item.content"
      ></b-collapse>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "JukiAccordion",
  props: {
    contentlist: {
      type: Object,
      required: true
    },
    right: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      visible: []
    };
  },
  computed: {
    generatedId() {
      const idList = [];

      this.contentlist.content.forEach(el => {
        let id = el.title.split(" ")[0].toLowerCase();
        let number = 0;
        while (idList.find(elem => elem === id) !== undefined) {
          id = id + number;
          number++;
        }
        idList.push(id);
      });
      return idList;
    },
    accordionName() {
      return this.contentlist.name;
    }
  },
  methods: {
    toggle(index) {
      this.$set(this.visible, index, !this.visible[index]);
    }
  },
  mounted() {
    for (let i = 0; i < this.contentlist.content.length; i++) {
      this.visible.push(false);
    }
  }
};
</script>

<style lang="scss">
.jukiaccordion {
  h3 {
    cursor: pointer;
  }
}

.acc-toggle {
  img {
    width: 30px;
    height: 30px;
  }
  cursor: pointer;
  display: block;
  width: 10px;
  position: absolute;
}

.accordion-content {
  font-size: 12pt;
  overflow: auto;
  img {
    margin: 0 10px;
    border: 1px solid black;
    max-width: 100%;
    height: auto;
  }
}

.left {
  .acc-toggle {
    left: 12px;
  }
  .accordion-content {
    border-left: 1px solid #000;
  }
}

.right {
  .accordion-content {
    border-right: 1px solid #000;
  }
  h3 {
    text-align: right;
  }
  .acc-toggle {
    right: 24px;
    img {
      transform: rotateY(180deg);
    }
  }
}

//Animation for Collapse

.acc-toggle-down {
  animation-name: fall;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}
@keyframes fall {
  from {
    top: 24px;
  }
  to {
    top: 95%;
  }
}
</style>
