// import axios from '@/store/axiosInstance';

import axios from "../axiosInstance.js"

const state = {
  news: [],
  selectedNewsArrayId: 0
}

const getters = {
  getNews: state => state.news,
  getSelectedNews: state => state.news[state.selectedNewsArrayId],
}

const actions = {
  fetchNews({
    commit
  }) {
    axios.get("/items/news?status=published&fields=*,bild.data,bild.height&sort=-created_on")
      .then(res => commit("setNews", res.data.data))
      .catch(err => console.error(err))
  }
}

const mutations = {
  setNews: (state, news) => state.news = news,
  setSelectedNewsArrayId: (state, id) => state.selectedNewsArrayId = id
}

export default {
  state,
  getters,
  actions,
  mutations
}
