import axios from '@/store/axiosInstance';

const state = {
  allDates: [],
}

const getters = {
  getAllDates: (state) => state.allDates
}

const actions = {
  fetchDates({ commit }) {
    axios.get("/items/termine?status=published")
      .then(res => commit("setAllDates", res.data.data))
      .catch(err => console.error(err));
  },
}

const mutations = {
  setAllDates: (state, dates) => state.allDates = dates,
}

export default {
  state,
  getters,
  actions,
  mutations
}
