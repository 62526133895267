<template>
  <b-container fluid class="mt-5 bg-dark news-wrapper">
    <b-container class="py-5 bg-dark" id="news">
      <b-row>
        <b-col class="text-light">
          <h2>Aktuelles</h2>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <stack
          :column-min-width="300"
          :monitor-images-loaded="true"
          :gutter-width="15"
          :gutter-height="15"
        >
          <stack-item v-for="(item, index) in getNews" :key="index">
            <b-card
              :img-src="image(item)"
              img-top
              :title="item.titel"
              tag="article"
              class="stack-item mb-2"
            >
              <b-card-text>
                {{ item.kurzbeschreibung }}
              </b-card-text>
              <b-button variant="info" center @click="openNews(index)"
                >Weiterlesen</b-button
              >
            </b-card>
          </stack-item>
        </stack>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "News",
  components: { Stack, StackItem },
  props: {
    isMobil: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getNews", "getSelectedNews"]),
    title() {
      return this.getSelectedNews.titel;
    },
  },
  methods: {
    ...mapMutations(["setSelectedNewsArrayId"]),
    openNews(id) {
      this.setSelectedNewsArrayId(id);
      this.$bvModal.show("news-modal");
    },
    image(item) {
      if (item.bild !== null) {
        return item.bild.data.full_url;
      }
      return "";
    },
  }
};
</script>

<style lang="scss" scoped>
.news-wrapper {
  box-shadow: 1px 1px 31px 0px rgba(0, 0, 0, 0.75);
}

.card {
  border-radius: 3px 4px;
  box-shadow: 1px 4px 18px -1px rgba(0, 0, 0, 1);
  img {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .card {
    img {
      display: initial;
    }
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
