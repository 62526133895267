<template>
  <b-modal
    id="news-modal"
    class="news-modal"
    ok-only
    ok-title="Schließen"
    ok-variant="info"
    :title="title"
    centered
    size="lg"
    scrollable
  >
    <b-container>
      <b-row>
        <b-col>
          <b-img-lazy fluid height="400" center :src="image"></b-img-lazy>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <i>{{ date }}</i>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="12">
          <div v-html="news" class="text-block"></div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsModal",
  computed: {
    ...mapGetters(["getSelectedNews"]),
    title() {
      if (this.getSelectedNews !== undefined) {
        return this.getSelectedNews.titel;
      } else {
        return "";
      }
    },
    image() {
      if (
        this.getSelectedNews !== undefined &&
        this.getSelectedNews.bild !== null
      ) {
        return this.getSelectedNews.bild.data.full_url;
      } else {
        return "";
      }
    },
    date() {
      return this.getSelectedNews
        ? new Date(this.getSelectedNews.created_on).toLocaleDateString()
        : "";
    },
    news() {
      if (this.getSelectedNews !== undefined) {
        return this.getSelectedNews.news;
      } else {
        return "";
      }
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss">
</style>
