<template>
  <b-container>
    <b-row class="calendar-head bg-dark text-white">
      <b-col cols="2" align-self="center">
        <div @click="switchMonth(-1)" class="calendar-button pointer">
          <b-icon-arrow-left />
        </div>
      </b-col>
      <b-col cols="8" class="text-center" align-self="center">
        <div>
          <h2>{{ calendarHead }}</h2>
        </div>
      </b-col>
      <b-col cols="2" align-self="center" class="text-right">
        <div @click="switchMonth(1)" class="calendar-button pointer">
          <b-icon-arrow-right />
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col align-self="center" class="no-padding">
        <table class="table">
          <thead>
            <tr>
              <th>Mo</th>
              <th>Di</th>
              <th>Mi</th>
              <th>Do</th>
              <th>Fr</th>
              <th>Sa</th>
              <th class="text-primary">So</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in generateCalendarSheet" :key="index">
              <td
                :class="[
                  {
                    'text-primary':
                      col.date.getDay() === 0 &&
                      col.date.getMonth() === NOW.getMonth(),
                  },
                  { 'inactive-month': col.date.getMonth() !== NOW.getMonth() },
                ]"
                v-for="(col, ind) in row"
                :key="ind"
              >
                <Day :dayDate="col" />
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Day from "@/components/calendar/Day.vue";

import { mapGetters } from "vuex";

export default {
  name: "Calendar",
  components: {
    Day,
  },
  data() {
    return {
      MONTH_NAME: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      NOW: new Date(),
    };
  },
  computed: {
    ...mapGetters(["getAllDates"]),
    generateCalendarSheet() {
      let date = this.NOW;
      date.setDate(1);
      date = this.getMonday(date);

      const sheet = [];
      let row = [];
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 7; j++) {
          const eventList = this.getAllDates.filter(function (el) {
            const d = new Date(el.datum);
            return (
              d.getMonth() === date.getMonth() &&
              d.getDate() === date.getDate() &&
              d.getFullYear() === date.getFullYear()
            );
          });
          row.push({
            date: new Date(date),
            events: eventList,
          });
          date.setDate(date.getDate() + 1);
        }
        sheet.push(row);
        row = [];
      }
      return sheet;
    },
    calendarHead() {
      return (
        this.MONTH_NAME[this.NOW.getMonth()] + " " + this.NOW.getFullYear()
      );
    },
  },
  watch: {},

  methods: {
    getMonday(date) {
      date = new Date(date);
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() - 1);
      }
      return date;
    },
    switchMonth(summand) {
      const date = new Date(this.NOW.getTime());
      date.setMonth(date.getMonth() + summand);
      this.NOW = date;
    },
  },
};
</script>

<style lang='scss' scoped>
.calendar-head {
  padding: 10px;
  box-shadow: 1px 4px 18px -1px rgba(0, 0, 0, 0.7);
  border: 0 solid black;
  h2 {
    margin: 0;
  }
}
.calendar-button {
  font-size: 40px;
  &:hover {
    color: #999;
  }
}

.inactive-month {
  color: #ccc;
}
table {
  table-layout: fixed;
}
th {
  font-size: 20px;
  text-align: center;
}
.no-padding {
  padding: 0px;
}

tr {
  min-height: 40px;
}
</style>
