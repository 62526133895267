<template>
  <div class="footer-dark">
    <div>
      <b-container class="pt-3 pb-3 text-light">
        <b-row>
          <b-col md="4" class="footer-left">
            <h5>Adresse</h5>
            <p>
              August-Bebel-Str. 66
              <br />99974 Mühlhausen
            </p>
            <p>
              Tel.: {{ getPhone }} <br />E-Mail:
              <a :href="'mailto:' + getMail">{{ getMail }}</a>
            </p>
          </b-col>
          <b-col md="4" class="footer-center">
            <router-link to="/impressum">Impressum</router-link>
            <br />
            <router-link to="/datenschutz">Datenschutz</router-link>
          </b-col>
          <b-col md="4" class="footer-right">
            <a
              href="https://www.ekuja.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon-box-arrow-up-left flip-h></b-icon-box-arrow-up-left> Ekuja
            </a>

            <br />
            <a
              href="https://www.facebook.com/JugendkircheMHL/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon-facebook></b-icon-facebook> Facebook
            </a>

            <br />
            <a
              href="https://www.instagram.com/jugendkirche.muehlhausen/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon-instagram></b-icon-instagram> Instagram
            </a>

            <br />
            <a
              href="https://www.youtube.com/channel/UC9fQB5dOfpvzI4C-CQECnFA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-icon-youtube></b-icon-youtube> YouTube
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <hr class="line-light" />
    <b-container fluid>
      <b-container class="pb-3 text-light">
        <b-col md="12" class="footer-center"
          >&copy; {{ new Date().getFullYear() }} Evangelische Kinder- und
          Jugendarbeit Mühlhausen</b-col
        >
      </b-container>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters(["getPhone", "getMail"])
  }
};
</script>

<style lang='scss' scoped>
.footer-dark {
  background-color: #222;
}

.footer-left,
.footer-center,
.footer-right {
  text-align: center;
  padding-top: 20px;
  a {
    color: white;
    &:hover {
      color: #aaa;
      cursor: pointer;
    }
  }
}
.line-light {
  border-color: rgba(162, 162, 162, 0.1);
  margin-bottom: 0;
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .footer-left {
    text-align: left;
  }

  .footer-right {
    text-align: right;
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
