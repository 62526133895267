<template>
  <header :class="{ 'header-mobile': (!isWideEnough || isMobile)}">
    <div class="overlay"></div>
    <video
      v-if="isWideEnough && !isMobile"
      playsinline="playsinline"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
      id="header-video"
    >
      <source :src="require('@/assets/WebsiteJuki.webm')" type="video/webm" />
    </video>
    <b-container class="h-100">
      <div class="d-flex h-100 text-center align-items-center">
        <div class="w-100 text-white">
          <h1 class="display-3">Jugendkirche Mühlhausen</h1>

          <p class="lead mb-0">Hören. Erleben. Weitersagen.</p>
          <b-button class="start-button" to="/" v-scroll-to="'#dates, 75px'">Aktuelle Termine</b-button>
        </div>
      </div>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "VideoHeader",
  components: {},
  props: {
    isWideEnough: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
    isMobile: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
  }
};
</script>

<style lang="scss" scoped>
header {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  h1 {
    font-size: 30pt;
  }
  .container {
    position: relative;
    z-index: 2;
  }
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }
  .start-button {
    margin-top: 20px;
  }
}

.header-mobile {
  background: url("./../../assets/altar.jpg") black no-repeat center center scroll;
  background-size: cover;
}

@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
