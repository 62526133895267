<template>
  <b-container class="mb-3">
    <JukiAccordion :contentlist="content" />
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import JukiAccordion from "@/components/general/JukiAccordion.vue";

export default {
  name: "AppointmentList",
  components: {
    JukiAccordion,
  },
  computed: {
    ...mapGetters(["getAllDates"]),
    content() {
      const now = new Date();
      const eventList = [];

      const events = this.getAllDates.filter((el) => {
        const date = new Date(el.datum);
        return date.getTime() > now.getTime();
      });

      events.sort((a, b) => {
        const timeA = new Date(a.datum + "T" + a.zeit).getTime();
        const timeB = new Date(b.datum + "T" + b.zeit).getTime();
        if (timeA > timeB) return 1;
        if (timeA < timeB) return -1;
        return 0;
      });
      events.forEach((el) => {
        const date = new Date(el.datum + "T" + el.zeit);
        const obj = {};
        obj.title = el.titel;
        obj.content =
          "<div><p><strong>Wann? </strong>" +
          date.toLocaleString() +
          "</p><p><strong>Wo? </strong>" +
          el.ort +
          "</p></div><div>" +
          el.beschreibung +
          "</div>";
        eventList.push(obj);
      });

      return {
        name: "EventList",
        content: eventList,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
