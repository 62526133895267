<template>
  <div class="day">
    <b-row>
      <b-col cols="1">
        <h5>
          <b>{{ dayDate.date.getDate() }}</b>
        </h5>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-white">
        <Appointment
          v-for="(event, index) in sortedEventList"
          :key="index"
          :appoint="event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Appointment from "@/components/calendar/Appointment.vue";

export default {
  name: "Day",
  components: {
    Appointment,
  },
  props: {
    dayDate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    sortedEventList() {
      const events = this.dayDate.events;
      events.sort((a, b) => {
        const timeA = new Date(a.datum + "T" + a.zeit).getTime();
        const timeB = new Date(b.datum + "T" + b.zeit).getTime();
        if (timeA > timeB) return 1;
        if (timeA < timeB) return -1;
        return 0;
      });
      return events;
    },
  },
};
</script>

<style lang="scss" scoped>
.day {
  min-height: 75px;
    .col{
    padding-right: 0;
  }
}
</style>
