<template>
  <div>
    <div class="termin" :id="generateId" @click="togglePopover(generateId)">
      <div class="title">{{ title }}</div>
    </div>
    <b-popover
      :target="generateId"
      placement="auto"
      triggers="manual"
      custom-class="event-popover"
      variant="danger"
      :title="title"
    >
      <b-row>
        <b-col md="12">
          <strong> Wann? </strong>
          {{ new Date(appoint.datum + " " + appoint.zeit).toLocaleString() }}
          Uhr
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <strong> Wo? </strong>
          {{ appoint.ort }}
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <p>
            <strong>Was?</strong>
          </p>
          <div v-html="appoint.beschreibung"></div>
        </b-col>
      </b-row>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "Appointment",
  props: {
    appoint: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowPopOver: false,
    };
  },
  computed: {
    title() {
      return this.appoint.titel;
    },
    generateId() {
      return this.appoint.id + " ";
    },
    dateTitleString() {
      const appoint = this.appoint.date;
      return (
        appoint.getHours() +
        ":" +
        appoint.getMinutes().toString().padStart(2, 0)
      );
    },
  },
  methods: {
    togglePopover(id) {
      if (this.isShowPopOver) {
        this.$root.$emit("bv::hide::popover", id);
        this.isShowPopOver = false;
      } else {
        this.isShowPopOver = true;
        this.$root.$emit("bv::hide::popover");
        this.$root.$emit("bv::show::popover", id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.termin {
  background-color: #ffc100;
  color: #333;
  margin-bottom: 2px;
  padding: 4px;
  display: flex;
  border-radius: 3px 4px;
  justify-content: space-between;
  box-shadow: 1px 4px 18px -1px rgba(0, 0, 0, 1);
  margin: 5px 0;
  cursor: pointer;

}

.time {
  text-align: left;
  font-weight: bold;
}

.title {
  hyphens: auto;
}

.event-popover {
  min-width: 360px;
font-size: 12pt;
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
