import axios from '@/store/axiosInstance';

const state = {
  wasgeht: [],
  contact: {},
  ueber_uns: [],
  pictures: [],
  downloads: []
}

const getters = {
  wasgeht: state => state.wasgeht,
  getPhone: state => state.contact.telefonnummer,
  getMail: state => state.contact.email,
  ueberUns: state => state.ueber_uns,
  gallery: state => state.pictures,
  downloads: state => state.downloads
}

const actions = {
  fetchWasGeht({
    commit
  }) {
    axios.get("/items/was_geht?status=published").then(res => {
      commit("setWasGeht", res.data.data);
    }).catch(err => {
      console.error(err);
    });
  },
  fetchUeber({
    commit
  }) {
    axios.get("/items/ueber_uns?status=published").then(res => {
      commit("setUeber", res.data.data);
    }).catch(err => {
      console.error(err);
    });
  },

  fetchContact({
    commit
  }) {
    axios.get("/items/kontakt")
      .then(res => commit("setContact", res.data.data[0]))
      .catch(err => console.error(err));
  },
  fetchDownloads({ commit }) {
    axios.get("/items/downloads?fields=*,status=published&fields=*,download.data")
      .then(res => commit("setDownloads", res.data.data))
      .catch(err => console.log(err));
  },
  fetchGallery({ commit }) {
    axios.get("/items/bilder_files?fields=*,album.*,bilder.data,bilder.description&filter[album.status][eq]=published")
      .then(res => {
        const data = res.data.data;
        const sortedByAlbum = [];
        const albumIds = new Set();
        for (const value of data) {
          albumIds.add(value.album.id);
        }
        for (const val of albumIds) {
          const album = data.filter(el => el.album.id === val);
          const albumObj = Object.assign({}, album[0].album);
          albumObj.bilder = [];
          album.forEach(el => albumObj.bilder.push(el.bilder));
          sortedByAlbum.push(albumObj);
        }

        commit("setPictures", sortedByAlbum);
      })
      .catch(err => console.log(err));
  },
}

const mutations = {
  setWasGeht: (state, wasGeht) => (state.wasgeht = wasGeht),
  setContact: (state, contact) => (state.contact = contact),
  setUeber: (state, ueber) => (state.ueber_uns = ueber),
  setPictures: (state, album) => (state.pictures = album),
  setDownloads: (state, down) => (state.downloads = down)
}

export default {
  state,
  getters,
  actions,
  mutations
}
