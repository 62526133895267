<template>
  <b-container class="mt-5 mb-5" id="downloads">
    <b-row>
      <b-col>
        <h2>Downloads</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-list-group horizontal="md">
          <b-list-group-item
            class="download-icons"
            v-for="(download, index) in getDownloads"
            :key="index"
          >
            <a
              :href="download.download.data.full_url"
              target="_blank"
              rel="noopener noreferrer"
              ><b-icon-download></b-icon-download> {{ download.titel }}</a
            >
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Downloads",

  computed: {
    ...mapGetters(["downloads"]),
    getDownloads() {
      const downloads = this.downloads;
      return downloads;
    }
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.download-icons {
  font-size: 14pt;
}
</style>
