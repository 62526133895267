<template>
  <b-container class="text-block imprint">
    <b-row>
      <b-col>
        <h2>Impressum</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</p>
        <p>
          <strong>Julia Stein</strong>
          (Dipl. Soz.päd./Soz.arb. (FH))<br />
          <small>
            Referentin für die Arbeit mit Kindern, Jugendlichen und Familien<br>
            im Ev. Kirchenkreis Mühlhausen
          </small>
          <br />
        </p>
        <p>
          E-Mail:
          <a href="mailto:julia.stein@ekuja.de">julia.stein@ekuja.de</a>
          <br />
          Telefon: <a href="tel:+493601837946"> 0 3601 / 83 79 46 </a><br />
        </p>
        <address class="">
          Ev. Kirchenkreis Mühlhausen<br>
          Bei der Marienkirche 9<br>
          99974 Mühlhausen
        </address>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Imprint"
};
</script>

<style lang="scss" scoped>
.imprint {
  margin-top: 100px;
}

address {
  font-size: 14pt;
}
</style>
