<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
      <b-navbar-brand to="/">
        <img
          :src="require('@/assets/favicon.png')"
          alt="Jugendkirche Mühlhausen"
          width="30px"
        />
        Jugendkirche Mühlhausen
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/" v-scroll-to="'#top, 0px'">Start</b-nav-item>
          <b-nav-item to="/" v-scroll-to="'#news, 75px'">Aktuelles</b-nav-item>
          <b-nav-item to="/" v-scroll-to="'#dates, 75px'">Termine</b-nav-item>
          <b-nav-item to="/" v-scroll-to="'#whatsup, 75px'"
            >Was geht?</b-nav-item
          >
          <b-nav-item to="/" v-scroll-to="'#ueber, 75px'">Über uns</b-nav-item>
          <b-nav-item to="/" v-scroll-to="'#pictures, 75px'">Bilder</b-nav-item>
          <b-nav-item to="/" v-scroll-to="'#downloads, 75px'"
            >Downloads</b-nav-item
          >
          <b-nav-item to="/" v-scroll-to="'#contact, 75px'">Kontakt</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navigation"
};
</script>

<style lang="scss">
</style>
