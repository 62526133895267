<template>
  <b-container fluid class="m-0 p-0">
    <div class="parallax-wrapper">
      <Parallax
        :containerClass="'parallax-image'"
        :sectionClass="'parallax'"
        :speedFactor="0.25"
        :sectionHeight="50"
      >
        <img :src="img" alt="" srcset="" />
      </Parallax>
      <div class="quote-wrapper">
        <blockquote class="block quote mt-0 text-center">
          <p>{{ quote }}</p>
          <div v-if="quoteBy !== ''" class="blockquote-footer text-white">
            {{ quoteBy }}
          </div>
        </blockquote>
      </div>
    </div>
  </b-container>
</template>

<script>
import Parallax from "vue-parallaxy";

export default {
  name: "Quote",
  components: {
    Parallax
  },
  props: {
    img: {
      type: String,
      required: true
    },
    quote: {
      type: String,
      default: ""
    },
    quoteBy: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.parallax-wrapper {
  position: relative;
}

.parallax {
  min-height: 100vh;
  position: relative;
  min-height: 21.875rem;
  scroll-behavior: smooth;
  overflow: hidden;
  z-index: -1;
}
.parallax-image {
  width: 100%;
  overflow: hidden;
}

.quote-wrapper {
  color: #fff;
  width: 50%;
  top: 45%;
  left: 50%;
  position: absolute;
  .quote {
    position: relative;
    left: -50%;
    text-shadow: 2px 2px #333;
  }
  .block {
    display: block;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}
</style>
