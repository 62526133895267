<template>
  <b-container class="mt-5" id="pictures">
    <b-row>
      <b-col>
        <h2>Bilder</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <stack
          :column-min-width="300"
          :monitor-images-loaded="true"
          :gutter-width="15"
          :gutter-height="15"
        >
          <stack-item v-for="(item, index) in getGallery" :key="index">
            <b-img
              :src="item.bilder[0].data.full_url"
              class="album"
              fluid
              @click="openGalleryModal(index)"
              :id="'album' + index"
            ></b-img>
            <b-tooltip :target="'album' + index" triggers="hover">
              {{ item.titel }}
            </b-tooltip>
          </stack-item>
        </stack>
      </b-col>
    </b-row>
    <GalleryModal :albumIndex="selectedAlbum" />
  </b-container>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";
import { mapGetters } from "vuex";
import GalleryModal from "@/components/gallery/GalleryModal.vue";

export default {
  name: "Gallery",
  components: { Stack, StackItem, GalleryModal },
  data() {
    return {
      selectedAlbum: 0
    };
  },
  computed: {
    ...mapGetters(["gallery"]),
    getGallery() {
      const gallery = this.gallery;
      return gallery;
    }
  },
  methods: {
    openGalleryModal(index) {
      this.selectedAlbum = index;
      this.$bvModal.show("gallery-modal");
    }
  }
};
</script>

<style>
.album {
  cursor: pointer;
}
</style>
