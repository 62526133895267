<template>
  <div id="app" class="wrapper">
    <Navigation />
    <div class="body-wrapper">
      <router-view></router-view>
    </div>
    <Footer class="footer" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/general/Footer.vue";

import Navigation from "@/components/general/Navigation.vue";
export default {
  name: "Main",
  components: {
    Navigation,
    Footer
  },
  methods: {
    ...mapActions([
      "fetchContact",
      "fetchDates",
      "fetchWasGeht",
      "fetchNews",
      "fetchUeber",
      "fetchGallery",
      "fetchDownloads"
    ])
  },
  mounted() {
    this.fetchContact();
    this.fetchDates();
    this.fetchWasGeht();
    this.fetchNews();
    this.fetchUeber();
    this.fetchGallery();
    this.fetchDownloads();
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
html {
  scroll-behavior: smooth;
}
h1 {
  font-size: 32pt;
}
.pointer {
  cursor: pointer;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.body-wrapper {
  flex-grow: 1;
}

.footer {
  flex-shrink: 0;
}
</style>
