<template>
  <b-container class="mt-5 mb-3" id="ueber">
    <b-row>
      <b-col>
        <h2 class="text-right">Über uns</h2>
      </b-col>
    </b-row>
    <JukiAccordion :contentlist="contentList" right/>
  </b-container>
</template>

<script>
import JukiAccordion from "@/components/general/JukiAccordion.vue";
import { mapGetters } from "vuex";

export default {
  name: "Ueber",
  components: {
    JukiAccordion,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["ueberUns"]),
    contentList() {
      const result = {
        name: "ueberUns",
        content: [],
      };
      const contentArr = this.ueberUns;
      contentArr.forEach((element) => {
        const elem = {
          title: element.titel,
          content: element.inhalt,
        };
        result.content.push(elem);
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
