<template>
  <b-modal
    id="gallery-modal"
    class="gallery-modal"
    ok-only
    ok-title="Schließen"
    ok-variant="info"
    :title="title"
    centered
    size="xl"
    scrollable
  >
    <b-container>
      <b-row class="mt-2">
        <b-col md="12">
          <silent-box :gallery="albumItems"></silent-box>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "GalleryModal",
  props: {
    albumIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(["gallery"]),
    albumItems() {
      const albumItems = [];
      if (this.gallery.length > 0) {
        this.gallery[this.albumIndex].bilder.forEach(element => {
          const obj = {};
          obj.description = element.description;
          obj.thumbnailWidth = "220px";
          obj.alt = "Bild aus Album" + this.title;
          obj.src = element.data.full_url;
          albumItems.push(obj);
        });
      }

      return albumItems;
    },
    title() {
      return this.gallery.length > 0
        ? this.gallery[this.albumIndex].titel
        : "Album ansehen";
    }
  }
};
</script>

<style lang="scss">
.silentbox-item {
  margin-right: 1rem;
}
</style>
