<template>
  <div id="top">
    <VideoHeader :isMobile="isMobile" :isWideEnough="isWideEnough" />
    <b-container class="mt-5 mb-3 text-block">
      <h2>Hallo!!! Schön, dass du da bist!!!</h2>
      <h3>♥-lich Willkommen...</h3>
      <p>
        ...auf unserer Jugendkirchenseite im Netz. Schön, dass du vorbeischaust
        und dich über unsere Veranstaltungen informierst. Hier findest du alles,
        was in der Jugendkirche so geht: Termine unserer regelmäßigen Gruppen
        und Kreise und natürlich von unseren Gottesdiensten.
      </p>
      <p>
        Unser Gebäude wurde für unsere Arbeit neu gestaltet und ganz speziell
        für Jugendarbeit eingerichtet. Bei uns kannst du das, was Kirche macht,
        ganz neu entdecken und erleben, wie eine junge Generation durchstartet
        und Kirche gestaltet. Die Jugendkirche bietet außerdem noch viele
        Möglichkeiten für Workshops und Seminare für Jugendgruppen und
        Schulklassen an. Zum Beispiel haben wir Module für Religion- und
        Ethikklassen entwickelt mit ganz interessanten Themen. Klick dich
        einfach mal durch und vielleicht findest du was für dich!
      </p>
    </b-container>

    <News />
    <Quote
      v-if="isWideEnough && !isMobile"
      :img="require('@/assets/lounge.jpg')"
      :quote="'Jesus Christus spricht: Seid barmherzig, wie auch euer Vater barmherzig ist!'"
      :quoteBy="'Lukas 6:36 '"
    ></Quote>
    <b-container class="mt-5 mb-3" id="dates">
      <b-row>
        <b-col>
          <h2>Termine</h2>
        </b-col>
      </b-row>
    </b-container>

    <Calendar class="mb-5" v-if="isWideEnough && !isMobile" />
    <AppointmentList v-else />

    <Quote
      v-if="isWideEnough && !isMobile"
      :img="require('@/assets/lounge.jpg')"
    ></Quote>
    <WhatsUp />
    <Ueber />
    <Gallery />
    <Downloads />
    <Contact />
    <NewsModal />
  </div>
</template>

<script>
import VideoHeader from "@/components/start/VideoHeader.vue";
import News from "@/components/news/News.vue";
import Calendar from "@/components/calendar/Calendar.vue";
import WhatsUp from "@/components/start/WhatsUp.vue";
import AppointmentList from "@/components/calendar/AppointmentList.vue";
import Quote from "@/components/quoteblock/Quote.vue";
import NewsModal from "@/components/news/NewsModal.vue";
import Ueber from "@/components/start/Ueber.vue";
import Contact from "@/components/start/Contact.vue";
import Gallery from "@/components/gallery/Gallery.vue";
import Downloads from "@/components/downloads/Downloads.vue";

export default {
  name: "Main",
  components: {
    VideoHeader,
    News,
    Calendar,
    WhatsUp,
    AppointmentList,
    Quote,
    NewsModal,
    Ueber,
    Contact,
    Gallery,
    Downloads
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isWideEnough() {
      return this.windowWidth >= 576;
    },
    isMobile() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        ) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }
  },
  methods: {
    resizeHandler() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeHandler);
  }
};
</script>
<style lang="scss">
body,
html {
  height: 100%;
}

h2 {
  font-size: 28pt;
}

.text-block p {
  font-size: 14pt;
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1200px) {
}
</style>
