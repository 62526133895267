<template>
  <b-container fluid class="bg-dark py-5 text-light" id="contact">
    <b-container>
      <b-row>
        <b-col md="12">
          <h2>Kontakt</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>Ihr findet uns auf folgenden Plattformen:</p>
        </b-col>
      </b-row>
      <b-row class="social-links my-3">
        <b-col md="4">
          <a
            href="https://www.facebook.com/JugendkircheMHL/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b-icon-facebook></b-icon-facebook>
          </a>
        </b-col>
        <b-col md="4">
          <a
            href="https://www.instagram.com/jugendkirche.muehlhausen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b-icon-instagram></b-icon-instagram>
          </a>
        </b-col>
        <b-col md="4">
          <a
            href="https://www.youtube.com/channel/UC9fQB5dOfpvzI4C-CQECnFA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b-icon-youtube></b-icon-youtube>
          </a>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col md="8" offset-md="2">
          <h2>Kontaktformular</h2>
          <b-form @submit.prevent.stop="sendMessage">
            <b-form-group
              id="input-group-1"
              label="Emailadresse:"
              label-for="email-field"
            >
              <b-form-input
                id="email-field"
                v-model="form.email"
                type="email"
                placeholder="Emailadresse eingeben"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Dein Name:"
              label-for="name-field"
            >
              <b-form-input
                id="name-field"
                v-model="form.name"
                placeholder="Name"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="fieldset-horizontal"
              description="Gib den Begriff ein, der nicht zu den anderen passt!"
              :label="captchaVariants[0].q"
              label-for="captcha-input"
            >
              <b-form-input id="captcha-input"></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Deine Nachricht:"
              label-for="message-field"
            >
              <b-form-textarea
                id="message-field"
                v-model="form.text"
                placeholder="Deine Nachricht"
                rows="3"
                required
              ></b-form-textarea>
            </b-form-group>
            <b-button type="submit">Absenden</b-button>
          </b-form>
        </b-col>
      </b-row> -->
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      form: {
        email: "",
        name: "",
        text: ""
      },
      captchaVariants: [
        {
          q: "Kreuz, Jesus, Dornenkrone, Bus",
          a: "Bus"
        },
        {
          q: "Fahrrad, Krippe, Maria, Stern",
          a: "Fahrrad"
        }
      ],
      variantNumber: 0
    };
  },
  methods: {
    sendMessage() {},
    generateVariant() {
      Math.random();
    }
  }
};
</script>

<style lang="scss" scoped>
.social-links {
  font-size: 50pt;
  text-align: center;
  a {
    color: #ffc100;
  }
}
</style>
