import Vue from 'vue'
import Vuex from 'vuex'

import date from "./modules/date";
import news from "./modules/news";
import content from "./modules/content";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    date,
    news,
    content
  }
})
