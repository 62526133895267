<template>
  <b-container class="mt-5 mb-3" id="whatsup">
    <b-row>
      <b-col>
        <h2>Was geht?</h2>
      </b-col>
    </b-row>
    <JukiAccordion :contentlist="contentList"/>
  </b-container>
</template>

<script>
import JukiAccordion from "@/components/general/JukiAccordion.vue";
import { mapGetters } from "vuex";

export default {
  name: "WhatsUp",
  components: {
    JukiAccordion,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["wasgeht"]),
    contentList() {
      const result = {
        name: "whatsup",
        content: [],
      };
      const contentArr = this.wasgeht;
      contentArr.forEach((element) => {
        const elem = {
          title: element.titel,
          content: element.inhalt,
        };
        result.content.push(elem);
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
